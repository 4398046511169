import React, { Component } from "react";
import feather from "feather-icons";

class Empty extends Component {
  render() {
    let svg = null;
    if (this.props.icon) {
      svg = feather.icons[this.props.icon].toSvg({
        width: 40,
        height: 40,
      });
    }
    return (
      <div className="py-5 text-center text-gray-500">
        <h2 className="text-2xl">
          {svg ? (
            <React.Fragment>
              <span
                className="flex justify-center mb-2"
                dangerouslySetInnerHTML={{ __html: svg }}
              />
            </React.Fragment>
          ) : null}
          {this.props.text}
        </h2>
      </div>
    );
  }
}

export default Empty;
