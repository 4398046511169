export const contextForStatus = (status) => {
  let avatarUrlFormat = "https://avatars.githubusercontent.com/{username}?s=60";

  if (
    "github_api_base_url" in status.meta &&
    status.meta.github_api_base_url !== "https://api.github.com"
  ) {
    avatarUrlFormat =
      status.meta.github_api_base_url.replace("/api/v3", "") +
      "/{username}.png";
  }

  if (status.meta.host_type !== "github") {
    // Only supports GitHub avatars right now
    avatarUrlFormat = null;
  }

  return { avatarUrlFormat };
};
