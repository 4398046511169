import React, { Component } from "react";
import Code from "./Code";

class Config extends Component {
  render() {
    return <Code code={this.props.data.config_text || ""} language="yaml" />;
  }
}

export default Config;
