/* eslint-disable */
import React, { Component } from "react";
import logo from "../pullapprove-logo.png";
import LoadJSON from "./LoadJSON.js";
import LoadURL from "./LoadURL.js";

class Start extends Component {
  state = { tab: null };
  render() {
    return (
      <div
        className="text-center mx-auto flex flex-col"
        style={{ maxWidth: "600px" }}
      >
        {!this.props.embedded ? (
          <img
            src={logo}
            alt="PullApprove logo"
            className="mt-10 mb-6 max-w-full w-72 mx-auto"
          />
        ) : null}
        <p className="mt-4 text-gray-600 mx-auto">
          View the status of a PR by clicking the link on a commit status.
        </p>
        <p className="mt-2 text-gray-500 text-sm mx-auto">
          Or manually load from{" "}
          <a
            href="#"
            className="text-gray-600 underline"
            onClick={() => this.setState({ tab: "json" })}
          >
            JSON
          </a>{" "}
          or{" "}
          <a
            href="#"
            className="text-gray-600 underline"
            onClick={() => this.setState({ tab: "url" })}
          >
            URL
          </a>
        </p>
        {this.state.tab === "json" ? (
          <LoadJSON loadHandler={this.props.loadFromTextHandler} />
        ) : null}
        {this.state.tab === "url" ? (
          <LoadURL loadHandler={this.props.loadFromURLHandler} />
        ) : null}
      </div>
    );
  }
}

export default Start;
