import React, { Component } from "react";
import logo from "../pullapprove-logo.png";
import * as Sentry from "@sentry/browser";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="text-center my-10">
          <img src={logo} className="w-72 mx-auto" alt="PullApprove logo" />
          <p className="text-red-500 mt-3">
            There was an error loading the PR.
          </p>
          <a
            className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-sm text-white mt-4 inline-block shadow"
            href="https://www.pullapprove.com/contact/"
          >
            Report feedback
          </a>
        </div>
      );
    } else {
      // when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
