import React, { Component } from "react";
import Code from "./Code";

class StatusJSON extends Component {
  render() {
    return (
      <Code code={JSON.stringify(this.props.data, null, 2)} language="json" />
    );
  }
}

export default StatusJSON;
