import React, { Component } from "react";
import Expressions from "./Expressions.js";
import { AppContext } from "../App.js";
import { marked } from "marked";
import dompurify from "dompurify";

class Group extends Component {
  userRows(users, state) {
    const colors = {
      pending: "text-gray-700 uppercase bg-gray-200 rounded-sm",
      approved: "text-green-800 uppercase bg-green-100 rounded-sm",
      rejected: "text-red-700 uppercase bg-red-100 rounded-sm",
      unavailable: "capitalize text-gray-500",
      available: "capitalize text-gray-700",
    };
    return users.map((username) => (
      <tr key={username}>
        <td width="250px" className="pb-1.5 flex items-center">
          <AppContext.Consumer>
            {(context) => {
              if (context.avatarUrlFormat) {
                return (
                  <img
                    src={context.avatarUrlFormat.replace(
                      "{username}",
                      username.replace("[bot]", "")
                    )}
                    alt=""
                    className="inline-block w-6 h-6 mr-2 rounded border-sm"
                  />
                );
              }
            }}
          </AppContext.Consumer>
          {username}
        </td>
        <td className="pb-1.5 text-right">
          <span className={colors[state] + " px-1"}>{state}</span>
        </td>
      </tr>
    ));
  }
  render() {
    const { name, data } = this.props;
    return (
      <div className="mb-6 border border-gray-300 rounded-sm">
        <div className="flex items-center justify-between px-4 py-3 bg-gray-100 border-b border-gray-200">
          <div>
            <span className="font-mono font-bold text-gray-800">{name}</span>
          </div>
          <div className="flex items-center space-x-3 text-sm">
            <span>
              {data.score} of {data.required} required
            </span>
            {data.type === "required" ? null : (
              <div className="capitalize">{data.type}</div>
            )}
            <div
              className={
                data.is_active && data.is_passing
                  ? "rounded-full capitalize px-3 py-1 bg-green-600 text-white"
                  : data.state === "rejected"
                  ? "rounded-full capitalize px-3 py-1 bg-red-600 text-white"
                  : "rounded-full capitalize px-3 py-1 bg-gray-300 text-gray-900"
              }
            >
              {data.state}
              {data.is_active ? null : <span> (Inactive)</span>}
            </div>
          </div>
        </div>

        <div className="p-4">
          {data.description ? (
            <div
              className="mb-4"
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(marked(data.description)),
              }}
            />
          ) : null}

          <div className="space-y-6 sm:flex sm:space-y-0">
            <div className="sm:w-2/5">
              <h5 className="mb-2 text-lg font-semibold">Reviewers</h5>

              <table className="table w-full">
                <tbody>
                  {this.userRows(data.users_rejected, "rejected")}
                  {this.userRows(data.users_approved, "approved")}
                  {this.userRows(data.users_pending, "pending")}
                  {this.userRows(data.users_unavailable, "unavailable")}
                  {this.userRows(data.users_available, "available")}
                </tbody>
              </table>
            </div>
            <div className="sm:w-3/5 sm:pl-24">
              {data.conditions && data.conditions.length > 0 ? (
                <div className="mb-4 overflow-x-scroll">
                  <h5 className="mb-2 text-lg font-semibold">Conditions</h5>
                  <Expressions data={data.conditions} />
                </div>
              ) : null}

              {data.requirements && data.requirements.length > 0 ? (
                <div className="mb-4 overflow-x-scroll">
                  <h5 className="mb-2 text-lg font-semibold">Requirements</h5>
                  <Expressions data={data.requirements} />
                </div>
              ) : null}

              <h5 className="mb-2 text-lg font-semibold">Settings</h5>
              <dl className="flex flex-wrap text-sm">
                <dt className="w-1/3 mt-1 font-bold text-gray-800">
                  <code>required</code>
                </dt>
                <dd className="w-2/3">
                  <code>{data.required}</code>
                </dd>

                <dt className="w-1/3 mt-1 font-bold text-gray-800">
                  <code>request</code>
                </dt>
                <dd className="w-2/3">
                  <code>{data.request}</code>
                </dd>

                <dt className="w-1/3 mt-1 font-bold text-gray-800">
                  <code>request_order</code>
                </dt>
                <dd className="w-2/3">
                  <code>{data.request_order}</code>
                </dd>

                <dt className="w-1/3 mt-1 font-bold text-gray-800">
                  <code>author_value</code>
                </dt>
                <dd className="w-2/3">
                  <code>{data.author_value}</code>
                </dd>

                <dt className="w-1/3 mt-1 font-bold text-gray-800">
                  <code>reviewed_for</code>
                </dt>
                <dd className="w-2/3">
                  <code>{data.reviewed_for}</code>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Group;
