import React, { Component } from "react";
import Tooltip from "@atlaskit/tooltip";

class Expressions extends Component {
  state = { popupOpen: false };
  render() {
    const { data } = this.props;
    if (data.length < 1) {
      return <div className="mb-3 text-gray-500">None</div>;
    }
    return (
      <table className="table">
        <tbody>
          {data.map((c) => (
            <tr key={c.condition || c.expression}>
              <td className="pr-2 align-top">
                <Tooltip
                  content={
                    c.result ? (
                      <pre>
                        <code>{c.result}</code>
                      </pre>
                    ) : (
                      <span className="italic">(Empty)</span>
                    )
                  }
                >
                  <span
                    className={
                      c.is_met
                        ? "text-green-700 uppercase bg-green-100 px-1 rounded-sm text-sm cursor-help"
                        : "text-red-700 uppercase bg-red-100 px-1 rounded-sm text-sm cursor-help"
                    }
                  >
                    {c.is_met ? "pass" : "fail"}
                  </span>
                </Tooltip>
              </td>
              <td>
                <pre>
                  <code className="text-sm">
                    <MultilineText text={c.condition || c.expression} />
                  </code>
                </pre>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

class MultilineText extends Component {
  state = { expanded: false };
  render() {
    const { text } = this.props;
    let display = text;
    let isMultiline = text.indexOf("\n") > -1;

    let cursor = "";
    if (isMultiline && this.state.expanded) {
      cursor = "cursor-zoom-out";
    } else if (isMultiline) {
      cursor = "cursor-zoom-in";
      const lines = text.split("\n");
      display = lines[0] + " ...";
    }

    return (
      <div
        className={cursor}
        onClick={() => {
          this.setState({ expanded: !this.state.expanded });
        }}
      >
        {display}
      </div>
    );
  }
}

export default Expressions;
