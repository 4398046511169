import React, { Component } from "react";
import { marked } from "marked";
import dompurify from "dompurify";

// https://docs.python.org/3/library/logging.html#levels
const LevelClasses = {
  CRITICAL: "bg-red-200 border-red-300 text-red-900",
  ERROR: "bg-red-200 border-red-300 text-red-900",
  WARNING: "bg-yellow-200 border-yellow-300 text-yellow-900",
  INFO: "bg-gray-200 border-gray-300 text-gray-900",
  DEBUG: "bg-gray-200 border-gray-300 text-gray-900",
  NOTSET: "bg-gray-200 border-gray-300 text-gray-900",
};

class Message extends Component {
  render() {
    return (
      <div
        className={"p-3 mb-2 rounded border " + LevelClasses[this.props.level]}
      >
        <strong className="mr-2">{this.props.level}:</strong>
        <div
          className="inline-block"
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(marked(this.props.content)),
          }}
        />
      </div>
    );
  }
}

class Logs extends Component {
  render() {
    if (!this.props.logs) {
      return;
    }
    const messages = this.props.logs.map((log, i) => (
      <Message key={i} level={log.level} content={log.content} />
    ));
    return <div>{messages}</div>;
  }
}

export default Logs;
