import React, { Component } from "react";

class Status extends Component {
  render() {
    let classes = "rounded p-4";

    if (this.props.state === "success") {
      classes += " text-green-900 bg-green-100 border border-green-200";
    } else if (this.props.state === "pending") {
      classes += " text-gray-900 bg-gray-100 border border-gray-200";
    } else if (this.props.state === "failure" || this.props.state === "error") {
      classes += " text-red-900 bg-red-100 border border-red-200";
    }

    return (
      <div className={classes}>
        <span className="capitalize">{this.props.state}</span> &mdash;{" "}
        {this.props.explanation}
      </div>
    );
  }
}

export default Status;
