import React, { Component } from "react";

class LoadURL extends Component {
  state = { content: "" };
  load = () => {
    try {
      this.props.loadHandler(this.state.content);
    } catch (e) {
      alert(e);
      return;
    }
  };
  render() {
    return (
      <div className="rounded border border-gray-300 p-4 mt-6">
        <p>Paste the URL to a PullApprove status JSON.</p>
        <textarea
          rows={5}
          style={{ fontSize: ".9rem" }}
          className="w-full border border-gray-200 p-2 font-mono mt-4"
          value={this.state.content}
          required={true}
          onChange={(e) => this.setState({ content: e.target.value })}
        />
        <button
          className="bg-blue-500 text-white w-full rounded-sm hover:bg-blue-600 px-4 py-2 mt-1"
          onClick={this.load}
        >
          Load URL
        </button>
      </div>
    );
  }
}

export default LoadURL;
