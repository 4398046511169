/* eslint-disable */
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import TimeAgo from "timeago-react";
import logo from "./pullapprove-logo.png";
import "./App.css";
import Config from "./components/Config.js";
import Groups from "./components/Groups.js";
import Status from "./components/Status.js";
import Start from "./components/Start.js";
import Logs from "./components/Logs.js";
import StatusJSON from "./components/StatusJSON.js";
import { contextForStatus } from "./context.js";

export const AppContext = React.createContext();

class App extends Component {
  state = { status: null, tab: "groups", embedded: false, error: null };
  componentDidMount() {
    const windowURL = new URL(window.location.href);
    const s = windowURL.searchParams.get("s");
    const url = windowURL.searchParams.get("url");
    const truncatedStorageUrl = windowURL.searchParams.get("t");
    if (s) {
      const d = decodeURIComponent(s);
      const decoded = atob(d);
      this.loadStatusFromText(decoded);
    } else if (url) {
      this.loadStatusFromURL(decodeURI(url));
    } else if (truncatedStorageUrl) {
      // Only used in enterprise
      const suffix = window.location.hostname.match(
        /pullapprove-public-(\w+)/
      )[1];
      const base = `https://pullapprove-storage-${suffix}.s3.amazonaws.com/reports/`;
      this.loadStatusFromURL(base + decodeURI(truncatedStorageUrl));
    }

    this.setState({ embedded: windowURL.searchParams.get("embedded") });
  }
  loadStatusFromText = (text) => {
    const data = JSON.parse(text);
    this.setState({ status: data });
  };
  loadStatusFromURL = (url) => {
    const t = this;
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        t.setState({ status: data });
      })
      .catch((error) => {
        console.error(error);
        t.setState({ error: error });
      });
  };
  render() {
    if (this.state.error) {
      throw this.state.error;
    }

    if (!this.state.status) {
      return (
        <Start
          loadFromTextHandler={this.loadStatusFromText}
          loadFromURLHandler={this.loadStatusFromURL}
          embedded={this.state.embedded}
        />
      );
    }

    return (
      <div
        className={
          this.state.embedded ? "w-full px-4" : "w-full max-w-6xl mx-auto px-4"
        }
      >
        <Helmet
          onChangeClientState={(newState, addedTags, removedTags) =>
            window.parent.postMessage({ title: newState.title }, "*")
          }
        >
          <title>{`${this.state.status.repo.full_name} #${this.state.status.pull_request.number} • PullApprove`}</title>
        </Helmet>
        <AppContext.Provider value={contextForStatus(this.state.status)}>
          <div className="flex items-center pt-6 pb-4 border-b border-gray-200">
            <h1 className="text-3xl font-light">
              {this.state.status.repo.full_name} #
              {this.state.status.pull_request.number}
            </h1>
            {!this.state.embedded ? (
              <img
                className="hidden ml-auto sm:block"
                src={logo}
                style={{ maxHeight: "25px" }}
                alt="PullApprove logo"
              />
            ) : null}
          </div>

          {this.state.status.meta.mode === "test" ? (
            <div className="p-4 my-4 text-yellow-900 bg-yellow-100 border border-yellow-200 rounded">
              <strong>You are in test mode!</strong> The status below is an
              example of what would happen if you used this configuration.
            </div>
          ) : null}

          {this.state.status.status ? (
            <div className="my-4">
              <Status
                state={this.state.status.status.state}
                explanation={this.state.status.status.explanation}
              />
            </div>
          ) : null}

          <ul className="flex border-b border-gray-300">
            <li>
              <button
                className={
                  this.state.tab === "groups"
                    ? "focus:outline-none px-4 py-3 inline-block rounded-t border-t border-r border-l border-gray-200 bg-white -mb-1"
                    : "focus:outline-none px-4 py-3 inline-block"
                }
                onClick={() => this.setState({ tab: "groups" })}
              >
                Groups
              </button>
            </li>
            <li>
              <button
                className={
                  this.state.tab === "config"
                    ? "focus:outline-none px-4 py-3 inline-block rounded-t border-t border-r border-l border-gray-200 bg-white -mb-1"
                    : "focus:outline-none px-4 py-3 inline-block"
                }
                onClick={() => this.setState({ tab: "config" })}
              >
                Config
              </button>
            </li>
            <li>
              <button
                className={
                  this.state.tab === "debug"
                    ? "focus:outline-none px-4 py-3 inline-block rounded-t border-t border-r border-l border-gray-200 bg-white -mb-1"
                    : "focus:outline-none px-4 py-3 inline-block"
                }
                onClick={() => this.setState({ tab: "debug" })}
              >
                Debug
                {(this.state.status.meta.logs || []).length > 0 ? (
                  <span className="px-1 ml-2 text-sm bg-gray-200 rounded">
                    {this.state.status.meta.logs.length}
                  </span>
                ) : null}
              </button>
            </li>
            <li
              className="flex items-center py-3 ml-auto text-xs text-gray-500"
              title={this.state.status.meta.timestamp}
            >
              Updated&nbsp;
              <TimeAgo datetime={this.state.status.meta.timestamp} />
            </li>
          </ul>

          <div className="pt-4">
            {this.state.tab === "groups" ? (
              <Groups data={this.state.status.status.groups} />
            ) : null}
            {this.state.tab === "config" ? (
              <Config data={this.state.status.config} />
            ) : null}
            {this.state.tab === "debug" ? (
              <React.Fragment>
                <Logs logs={this.state.status.meta.logs || []} />
                <StatusJSON data={this.state.status} />
              </React.Fragment>
            ) : null}
          </div>
        </AppContext.Provider>
      </div>
    );
  }
}

export default App;
